import breakpoints from '../utils/breakpoints';
import NavigationDrawer from './navigation-menu';
import MegaMenu from './megamenu';
import Search from './search';
import CountrySelector from './country-selector';
import triggerAnimationRestart from '../utils/triggerAnimationRestart';
import StickyHeader from './scrollable-navbar';

const keys = {
    escape: 'Escape',
};

class Header {
    constructor(shopifyHeader) {
        this.shopifyHeader = shopifyHeader;
        this.stickyHeader = new StickyHeader(document.querySelector(".section-site-header"));
        this.drawers = document.querySelectorAll('[data-drawer]');
        this.modals = document.querySelectorAll('[data-modal]');
        this.navigationMenu = new NavigationDrawer(
            document.querySelector('[data-navigation]')
        );
        this.desktopMenu = new MegaMenu(
            document.querySelector('[data-desktop-navigation]')
        );
        this.search = new Search(this);
        this.countrySelector = new CountrySelector(this);
        this.overlay = document.querySelector('.overlay');
        this.announcementBar = document.querySelector('.announcement-bar');
        this.announcementBarCloseButton = document.querySelector(
            '.announcement-bar__close-button'
        );

        this.drawers.forEach(drawer =>
            this.registerDrawerEventListeners(drawer)
        );
        this.modals.forEach(modal => this.registerModalEventListeners(modal));

        this.handleAnnouncementBarCloseButtonClick = this.handleAnnouncementBarCloseButtonClick.bind(
            this
        );
        this.handleEscPress = this.handleEscPress.bind(this);
        this.handleOverlayClick = this.handleOverlayClick.bind(this);

        if (this.announcementBarCloseButton) {
            this.announcementBarCloseButton.addEventListener(
                'click',
                this.handleAnnouncementBarCloseButtonClick
            );
        }
        this.mobileMediaQuery = window.matchMedia(
            `(max-width: ${breakpoints.wide - 1}px)`
        );

        this.listenIfScreenSizeChanges();
    }

    // eslint-disable-next-line no-unused-vars
    handleAnnouncementBarCloseButtonClick(e) {
        this.announcementBar.classList.add('u-visually-hidden');
    }

    toggleDrawer(drawer) {
        if (drawer.getAttribute('data-drawer-state') === 'open') {
            this.closeDrawer(drawer);
        } else {
            this.openDrawer(drawer);
        }
    }

    openDrawer(drawer) {
        drawer.setAttribute('data-drawer-state', 'open');
        this.overlay.setAttribute('data-blur', 'true');
        this.overlay.style.setProperty('--overlay-z-index', 40);
        this.overlay.setAttribute('data-state', 'visible');
        document.body.addEventListener('keyup', this.handleEscPress);
        this.overlay.addEventListener('click', this.handleOverlayClick);
        document.body.classList.add('has-open-drawer');
        drawer.focus();
    }

    closeDrawer(drawer) {
        window.dispatchEvent(
            new CustomEvent('drawer.closed', { detail: 'drawer closed' })
        );
        // Only close the drawer if it is open.
        if (drawer.getAttribute('data-drawer-state') === 'open') {
            const animationEndListener = () => {
                drawer.setAttribute('data-drawer-state', 'closed');
                drawer.removeEventListener(
                    'animationend',
                    animationEndListener
                );
            };
            drawer.addEventListener('animationend', animationEndListener);

            drawer.setAttribute('data-drawer-state', 'closing');
            this.overlay.setAttribute('data-state', 'hidden');

            triggerAnimationRestart(
                drawer,
                'data-drawer',
                drawer.dataset.drawer
            );
            document.body.classList.remove('has-open-drawer');
            drawer.blur();
        }
    }

    closeAllDrawers() {
        this.drawers.forEach(this.closeDrawer.bind(this));
    }

    openModal(modal) {
        modal.setAttribute('data-modal-state', 'open');
        this.overlay.setAttribute('data-blur', 'false');
        this.overlay.setAttribute('data-state', 'visible');

        document.body.addEventListener('keyup', this.handleEscPress);
        this.overlay.addEventListener('click', this.handleOverlayClick);
        modal.focus();
    }

    closeModal(modal) {
        // Only close the modal if it is open.
        if (modal.getAttribute('data-modal-state') === 'open') {
            modal.setAttribute('data-modal-state', 'closed');
            this.overlay.setAttribute('data-state', 'hidden');
            modal.blur();
        }
    }

    closeAllModals() {
        this.modals.forEach(this.closeModal.bind(this));
    }

    handleEscPress(e) {
        // Don't close if we're focused within a form element
        if (['INPUT', 'TEXTAREA', 'SELECT'].includes(e.target.tagName)) {
            return;
        }

        if (e.key === keys.escape) {
            this.closeAllDrawers();
            this.closeAllModals();
            document.body.removeEventListener('keyup', this.handleEscPress);
            this.overlay.removeEventListener('click', this.handleOverlayClick);
        }
    }

    listenIfScreenSizeChanges() {
        // 'Prior to Safari 14, MediaQueryList is based on EventTarget, so you must use addListener() and removeListener() to observe media query lists.'
        // I've added a try and catch statement here to allow MediaQueryList to work on Safari 13 while future proofing the code.
        try {
            // Chrome & Firefox
            this.mobileMediaQuery.addEventListener('change', e => {
                if (!e.matches) {
                    this.closeAllDrawers();
                }
            });
        } catch (e1) {
            try {
                // Safari
                this.mobileMediaQuery.addListener(e => {
                    if (!e.matches) {
                        this.closeAllDrawers();
                    }
                });
            } catch (e2) {
                console.error(e2);
            }
        }
    }

    // eslint-disable-next-line no-unused-vars
    handleOverlayClick(e) {
        // Close the drawers and modals if the user clicks on the overlay.
        this.closeAllDrawers();
        this.closeAllModals();
        document.body.removeEventListener('keyup', this.handleEscPress);
        this.overlay.removeEventListener('click', this.handleOverlayClick);
    }

    registerDrawerEventListeners(drawer) {
        const key = drawer.dataset.drawer;

        // Open triggers
        document
            .querySelectorAll(`[data-drawer-open="${key}"]`)
            .forEach(trigger =>
                trigger.addEventListener('click', () => this.openDrawer(drawer))
            );

        // Close triggers
        document
            .querySelectorAll(`[data-drawer-close="${key}"]`)
            .forEach(trigger =>
                trigger.addEventListener('click', () =>
                    this.closeDrawer(drawer)
                )
            );

        // Toggle triggers
        document
            .querySelectorAll(`[data-drawer-toggle="${key}"]`)
            .forEach(trigger =>
                trigger.addEventListener('click', () =>
                    this.toggleDrawer(drawer)
                )
            );
    }

    registerModalEventListeners(modal) {
        const key = modal.dataset.modal;

        // Open triggers
        document
            .querySelectorAll(`[data-modal-open="${key}"]`)
            .forEach(trigger =>
                trigger.addEventListener('click', () => this.openModal(modal))
            );
    }
}

document.addEventListener('DOMContentLoaded', () => {
    new Header(document.querySelector('#shopify-section-site-header'));
  });
  
  export default Header;
